import { Box } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Draft from "../../api/LIMS/Draft";
import WorkRequest from "../../api/WorkRequest/WorkRequest";
import WorkRequestFacility from "../../api/WorkRequest/WorkRequestFacility";
import WorkRequestStatus from "../../api/WorkRequest/WorkRequestStatus";
import WorkRequestType from "../../api/WorkRequest/WorkRequestType";
import FilterMenu from "../../components/FilterMenu";
import ModalMessages from "../../components/Modal/ModalSimpleButton";
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";
import { UXDataTable } from "../../components/UXDataTable";
import UserContext from "../../context/UserContext";
import {
  Roles,
  applyFiltersToArray,
  convertDateFormat,
  exportToCsv,
  featureToggle,
  hasRole,
} from "../../global";
import {
  GlobalButton,
  GlobalSecondaryButton,
  GlobalTabCollection,
  StyledTab,
} from "../../pages/styles";
import TableAllRequests from "./TableAllRequests";
import TableMyOpenRequests from "./TableMyOpenRequests";

const MyDraftCols = [
  {
    field: "draftName",
    headerName: "Draft Name",
    type: "button-text-draft-workRequest",
    path: "/newWorkRequests",
  },
  { field: "draftType", headerName: "Draft Type", type: "label" },
  { field: "draftOwnerEmail", headerName: "Draft Owner", type: "label" },
  { field: "createdDate", headerName: "Create Date", type: "datetime" },
  { field: "lastModifiedDate", headerName: "Last Modified", type: "datetime" },
];

const WorkRequests = ({ copyWorkRequest }) => {
  const [tabValue, setTabValue] = useState(0);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([
    {
      name: null,
      displayName: null,
      operator: null,
      enumValues: [],
      value: "",
    },
  ]);
  const [allRequests, setAllRequests] = useState([]);
  const [filteredAllRequests, setFilteredAllRequests] = useState([]);
  const [isAllRequestsLoading, setIsAllRequestsLoading] = useState(true);

  const [myRequests, setMyRequests] = useState([]);
  const [isMyRequestsLoading, setIsMyRequestsLoading] = useState(true);
  const currentUser = useContext(UserContext);
  const userEmail = currentUser.username;
  const userRoles = currentUser?.idTokenClaims.roles;
  const isDeveloperRole = hasRole(Roles.Developer, userRoles);

  const [requestTypes, setRequestTypes] = useState([]);
  const [requestFacilities, setRequestFacilities] = useState([]);
  const [requestStatuses, setRequestStatuses] = useState([]);

  const [myDrafts, setMyDrafts] = useState(null);
  const [draftsIsLoading, setDraftsIsLoading] = useState(true);

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = "Ok";
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesText, setModalMessagesText] = useState("");
  const exportRef = useRef([]);

  const [modalDeleteDraftOpen, setModalDeleteDraftOpen] = useState(false);
  const [modalDeleteDraftTitle, setModalDeleteDraftTitle] = useState("");
  const [modalDeleteDraftText, setModalDeleteDraftText] = useState("");
  const [modalDeleteDraftButton1Text, setModalDeleteDraftButton1Text] = useState("");
  const [modalDeleteDraftButton2Text, setModalDeleteDraftButton2Text] = useState("");
  const [removeDraft, setRemoveDraft] = useState();

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  function closeModalDeleteDraft() {
    setModalDeleteDraftOpen(false);
  }

  function openModalDeleteDraft(title, text, button1, button2, draft) {
    setModalDeleteDraftOpen(true);
    setModalDeleteDraftTitle(title);
    setModalDeleteDraftText(text);
    setModalDeleteDraftButton1Text(button1);
    setModalDeleteDraftButton2Text(button2);
    setRemoveDraft(draft);
  }

  const filterOptions = [
    { name: "id", displayName: "Request ID", type: "integer", enumValues: [] },
    {
      name: "typeName",
      displayName: "Category",
      type: "enum",
      enumValues: requestTypes,
      multiple: true,
    },
    {
      name: "description",
      displayName: "Description",
      type: "string",
      enumValues: [],
    },
    {
      name: "assignedToEmail",
      displayName: "Assigned To",
      type: "string",
      enumValues: [],
    },
    {
      name: "requestedByEmail",
      displayName: "Requested By",
      type: "string",
      enumValues: [],
    },
    {
      name: "createdDate",
      displayName: "Requested Date",
      type: "date",
      enumValues: [],
    },
    {
      name: "requestedCompletionDate",
      displayName: "Requested Completion Date",
      type: "date",
      enumValues: [],
    },
    {
      name: "facilityName",
      displayName: "Facility",
      type: "enum",
      enumValues: requestFacilities,
      multiple: true,
    },
    {
      name: "statusName",
      displayName: "Status",
      type: "enum",
      enumValues: requestStatuses,
      multiple: true,
    },
    {
      name: "parentWorkRequestID",
      displayName: "Parent WR ID",
      type: "integer",
      enumValues: [],
    },
  ]; //.sort((a,b) => a.displayName.localeCompare(b.displayName));

  const isAllRequestsInitLoading =
    isAllRequestsLoading && (!allRequests || allRequests.length === 0);

  useEffect(() => {
    let cancelPromise = false;

    WorkRequest.getMyOpenWorkRequests(userEmail).then((res) => {
      if (cancelPromise) return;
      setMyRequests(res);
      setIsMyRequestsLoading(false);
    });

    return () => {
      cancelPromise = true;
    };
  }, [userEmail]);

  useEffect(() => {
    let cancelPromise = false;
    setIsAllRequestsLoading(true);

    function getAllRequestsDataRecr(currPage, allData = []) {
      if (cancelPromise) return allData;
      WorkRequest.getAll(currPage, 20000).then((res) => {
        if (res === null || res.length === 0) {
          setIsAllRequestsLoading(false);
          return allData;
        } else {
          allData = [...allData, ...res];
          currPage++;
          setAllRequests(allData);
          return getAllRequestsDataRecr(currPage, allData);
        }
      });
    }

    WorkRequest.getAll(0, 100).then((res) => {
      if (res === null || res.length === 0) {
        setIsAllRequestsLoading(false);
      } else {
        setAllRequests(res); //Load initial limited data for performance
        getAllRequestsDataRecr(0); //Recursively load all data
      }
    });

    return () => {
      cancelPromise = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredArray = applyFiltersToArray(
      filters,
      allRequests.filter((item) => item.statusName !== "Draft"),
    );
    setFilteredAllRequests(filteredArray);
  }, [filters, allRequests]);

  useEffect(() => {
    let cancelPromise = false;

    if (myDrafts === null) {
      setDraftsIsLoading(true);

      Draft.getByUserAndType(userEmail, "workRequest").then((res) => {
        if (cancelPromise) return;
        setMyDrafts(res);
        setDraftsIsLoading(false);
      });
    }

    return () => {
      cancelPromise = true;
    };
  }, [userEmail, myDrafts]);

  useEffect(() => {
    let cancelPromise = false;

    WorkRequestType.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestTypes(
        res
          .filter((result) => result.isActive === true)
          .map((item) => item.typeName)
          .sort(),
      );
    });

    WorkRequestStatus.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestStatuses(
        res
          .filter((result) => result.isActive === true)
          .map((item) => item.statusName)
          .sort(),
      );
    });

    WorkRequestFacility.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestFacilities(
        res
          .filter((result) => result.isActive === true)
          .map((item) => item.facilityName)
          .sort(),
      );
    });

    return () => {
      cancelPromise = true;
    };
  }, []);

  const handleChange = (event, newValue) => {
    clearFiltersClick();
    setTabValue(newValue);
  };

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  };

  const clearFiltersClick = (event) => {
    setFilters([
      {
        name: null,
        displayName: null,
        operator: null,
        enumValues: [],
        value: null,
      },
    ]);
  };

  const RemoveDraft = () => {
    if (removeDraft && removeDraft.id) {
      Draft.delete(removeDraft.id).then((res) => {
        closeModalDeleteDraft();
        if (res.message === "Success") {
          openModalMessages("Draft Deleted", "Draft successfully deleted!");
          setMyDrafts(null);
        } else {
          openModalMessages(
            "Draft Failed to Delete",
            `${res.message}. Contact support if you feel this is an error.`,
          );
        }
      });
    }
  };

  const ConfirmRemoveDraft = (draft) => {
    if (draft && draft.id) {
      openModalDeleteDraft("Delete Draft", "Are you sure?", "Yes", "No", draft);
    }
  };

  const menuItemsDrafts = [
    {
      menuType: "text",
      redirectPath: "",
      text: "Delete Draft",
      onClickFunction: ConfirmRemoveDraft,
    },
  ];

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        exportRef.current = applyFiltersToArray(filters, myRequests);
        return (
          <TableMyOpenRequests
            filters={filters}
            myRequests={myRequests}
            isDataLoading={isMyRequestsLoading}
            userEmail={userEmail}
            isProcessing={false}
            copyWorkRequest={copyWorkRequest}
          />
        );
      }
      case 1: {
        exportRef.current = filteredAllRequests;
        return (
          <TableAllRequests
            filters={filters}
            filteredAllRequests={filteredAllRequests}
            isDataLoading={isAllRequestsInitLoading}
            isProcessing={false}
            userEmail={userEmail}
            copyWorkRequest={copyWorkRequest}
          />
        );
      }
      case 2: {
        return (
          <UXDataTable
            tableWidth="100%"
            cols={MyDraftCols}
            rows={myDrafts === null ? [] : myDrafts.sort((a, b) => b.draftName - a.draftName)}
            moreOptionsCell={true}
            enablePaging={true}
            noDataMessage={`No work request drafts found for ${userEmail}`}
            menuProps={menuItemsDrafts}
            defaultRowsPerPage={10}
            isDataLoading={draftsIsLoading}
            tableName={"blendDrafts"}
            enableSorting={true}
          />
        );
      }
      default: {
        alert(tabValue);
      }
    }
  };

  const renderFilterMenu = () => {
    switch (tabValue) {
      case 0: {
        return (
          <FilterMenu
            open={filteringOpen}
            setOpen={setFilteringOpen}
            applyBtnAction={applyFilters}
            cancelButtonAction={closeFiltering}
            filteringInfo={filterOptions}
            appliedFilters={filters}
            setAppliedFilters={setFilters}
          />
        );
      }
      case 1: {
        return (
          <FilterMenu
            open={filteringOpen}
            setOpen={setFilteringOpen}
            applyBtnAction={applyFilters}
            cancelButtonAction={closeFiltering}
            filteringInfo={filterOptions}
            appliedFilters={filters}
            setAppliedFilters={setFilters}
          />
        );
      }

      case 2: {
        return <></>;
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <div>
      <Box sx={{ bgcolor: "#fff", pt: 3, pb: 1 }} display="flex">
        <GlobalTabCollection
          style={{ marginRight: "1rem" }}
          scrollButtons="auto"
          variant="scrollable"
          value={tabValue}
          onChange={handleChange}
          aria-label="ant example">
          <StyledTab label="My Open Requests" />
          <StyledTab label="All Requests" />
          {!featureToggle.WorkRequestRedesign && <StyledTab label="My Drafts" />}
        </GlobalTabCollection>

        <GlobalButton
          style={{ marginTop: "-.8rem" }}
          variant="contained"
          component={Link}
          to="/newWorkRequests">
          Create New Request
        </GlobalButton>

        {(featureToggle.WorkRequestRedesign || isDeveloperRole) && (
          <GlobalButton
            style={{ marginTop: "-.8rem", marginLeft: "1.8rem" }}
            variant="contained"
            component={Link}
            to="/newWorkRequestRedesign">
            Create New Request Redesign
          </GlobalButton>
        )}

        <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
          <GlobalButton
            style={{ marginRight: "1rem" }}
            variant="contained"
            disabled={tabValue === 1 && isAllRequestsLoading}
            onClick={(e) =>
              exportToCsv(
                exportRef.current.map((item) => {
                  return {
                    ID: item.id,
                    Category: item.typeName,
                    Description: item.description,
                    Requester: item.requestedByEmail,
                    "Assigned To": item.assignedToEmail,
                    "Requested Date": convertDateFormat(item.createdDate, false),
                    "Requested Completion": convertDateFormat(item.requestedCompletionDate, false),
                    "Last Acceptable Date": convertDateFormat(
                      item.lastAcceptableCompletionDate,
                      false,
                    ),
                    Facility: item.facilityName,
                    Status: item.statusName,
                    "MWR Name": item.mwrName,
                  };
                }),
                "Work Requests",
              )
            }>
            Export CSV
          </GlobalButton>
          <GlobalButton
            // disabled={tabValue > 1}
            style={{ marginRight: "1rem" }}
            variant="contained"
            onClick={() => filterClick()}>
            Filters
          </GlobalButton>

          {!(filters[0].name === null) && (
            <GlobalSecondaryButton variant="contained" onClick={() => clearFiltersClick()}>
              Clear Filters
            </GlobalSecondaryButton>
          )}
        </Box>
        <Box sx={{ p: 1 }} />
      </Box>
      {renderSearchTab()}
      {renderFilterMenu()}
      {/* Informational Messages */}
      <ModalMessages
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}>
        <label>{modalMessagesText}</label>
      </ModalMessages>
      <ModalTwoButtons
        title={modalDeleteDraftTitle}
        button1Text={modalDeleteDraftButton1Text}
        button1Action={RemoveDraft}
        button2Text={modalDeleteDraftButton2Text}
        button2Action={closeModalDeleteDraft}
        open={modalDeleteDraftOpen}
        setOpen={setModalDeleteDraftOpen}>
        <div style={{ textAlign: "center" }}>
          <label>{modalDeleteDraftText}</label>
        </div>
      </ModalTwoButtons>
    </div>
  );
};

export default WorkRequests;
