import { useEffect, useState } from "react";
import { Box, FormControlLabel, IconButton, Switch } from '@mui/material'
import { Delete } from "@mui/icons-material";
import { StyledTableBody, StyledTableRow, UXDataTableWithoutBody } from '../../../../components/UXDataTable'
import MethodSelection from '../../../../components/MethodSelection'
import ModalTwoButtons from '../../../../components/Modal/ModalTwoButtons'
import ModalMessages from '../../../../components/Modal/ModalSimpleButton'
import { StyledTableCellLarge, StyledTableCellMedium, StyledTableCellSmall } from '../constants'
import { formatMidasNumber, somethingIsDifferent } from '../../../../global'
import AddSamples from "./AddSamples";
import LocationField from "../../../../components/LocationField";
import { GlobalButton } from "../../../styles";
import WorkRequestContainerManagementWithoutContainer from "./WorkRequestContainerManagementWithoutContainer";

const mySampleInfoCols = [
    "MIDAS #",
    "Description",
];


const SampleWithoutContainer = ({
    isReadOnly = false,
    mySamplesInfo,
    setMySamplesInfo,
    unitOfMeasure,
    addCommentComponent,
    workRequestId,
    setIsRequiredComment,
    isProcess,
    availableLocations,
    prepareData,
    myWorkRequest,
    setMyWorkRequest,
}) => {
    const [isMySampleInfoModalOpen, setIsMySampleInfoModalOpen] = useState(false);

    const [modalDeleteItemOpen, setModalDeleteItemOpen] = useState(false);
    const [modalDeleteItemButton1Text, setModalDeleteItemButton1Text] = useState('');
    const [modalDeleteItemButton2Text, setModalDeleteItemButton2Text] = useState('');
    const [modalDeleteItemTitle, setModalDeleteItemTitle] = useState('');
    const [modalDeleteItemText, setModalDeleteItemText] = useState('');

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const [selectedSampleInfoIndex, setSelectedSampleInfoIndex] = useState(null);
    const [selectedSampleInfoToDelete, setSelectedSampleInfoToDelete] = useState(null);
    const [methods, setMethods] = useState([]);
    const [containeringMethod, setContaineringMethod] = useState('');
    const [bulkLocation, setBulkLocation] = useState(null);

    const selectedSampleInfo = mySamplesInfo[selectedSampleInfoIndex];

    function closeModalMessages() {
        setModalMessagesOpen(false);
    }

    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    const handleMySampleInfoDeleteIcon = (mySampleInfo) => {
        openModalDeleteItem("Confirm Deletion", "Are you sure you want to delete this sample info?  This action cannot be reversed.", "Yes", "No")
        setSelectedSampleInfoToDelete(mySampleInfo);
    }

    function closeModalDeleteItem() {
        if (selectedSampleInfoToDelete) {
            setSelectedSampleInfoToDelete(null);
            setSelectedSampleInfoIndex(null);
        }
        setModalDeleteItemOpen(false);
    }

    function openModalDeleteItem(title, text, buttonText, button2Text) {
        setModalDeleteItemButton1Text(buttonText)
        setModalDeleteItemButton2Text(button2Text)
        setModalDeleteItemOpen(true);
        setModalDeleteItemTitle(title);
        setModalDeleteItemText(text);
    }

    function deleteItem() {
        if (selectedSampleInfoToDelete) {
            setMySamplesInfo([...mySamplesInfo.filter(f => f !== selectedSampleInfoToDelete)]);
            openModalMessages('Deleted', 'Sample info deleted with success!')
            if (selectedSampleInfoToDelete.some(s => s.id) && isProcess) setIsRequiredComment(true);
        }
        closeModalDeleteItem();
    }

    function onCloseMySampleInfoModal() {
        setIsMySampleInfoModalOpen(false);
    }

    useEffect(() => {
        if (selectedSampleInfo) {
            if (selectedSampleInfo?.testSubmissionJSON) {
                const parsedValue = JSON.parse(selectedSampleInfo.testSubmissionJSON);
                setMethods(parsedValue.methods ? parsedValue.methods : parsedValue);
                setContaineringMethod(parsedValue.containeringMethod);
            } else {
                setMethods([])
                setContaineringMethod('');
            }
        } else {
            setMethods([]);
            setContaineringMethod('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSampleInfoIndex])

    useEffect(() => {
        if (methods?.length) {
            setMySamplesInfo((prevList) => {
                return prevList.map(mySampleInfo => {
                    const keyMySample = mySampleInfo.id ?? `${mySampleInfo?.sample?.sampleName}`;
                    const keySelectedMySampleInfo = selectedSampleInfo?.id ?? `${selectedSampleInfo?.sample?.sampleName}`;
                    if (keyMySample === keySelectedMySampleInfo) {
                        const methodFiltered = methods.filter((x) => x.methodFacility);
                        const updatedMySampleInfo = {
                            ...mySampleInfo,
                            testSubmissionJSON: JSON.stringify({ containeringMethod: containeringMethod, methods: methodFiltered })
                        }
                        const changeMethods = somethingIsDifferent(selectedSampleInfo?.testSubmissionJSON, updatedMySampleInfo?.testSubmissionJSON);
                        if (mySampleInfo?.id && isProcess && changeMethods) setIsRequiredComment(true);
                        return updatedMySampleInfo;
                    }
                    return mySampleInfo;
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods, containeringMethod])

    const methodFacilitiesExternal = methods.filter(f => f.externalLaboratory);

    return (
        <>
            <Box display="flex" flexDirection="column">
                <FormControlLabel control={<Switch onChange={() => setMyWorkRequest({ ...myWorkRequest, hasContainer: !myWorkRequest.hasContainer })} checked={myWorkRequest.hasContainer} disabled={workRequestId} />} label="Has Container?" labelPlacement="start" />
                <UXDataTableWithoutBody
                    tableWidth='100%'
                    cols={mySampleInfoCols}
                    blankFirstHeader
                    tableId="mySamplesInfoTable"
                    rowLength={mySamplesInfo?.length}
                    noDataFoundMessage={'No Sample info found'}
                    enableAddIcon={!isReadOnly}
                    addToolTipText="Add new"
                    addFunction={() => {
                        setIsMySampleInfoModalOpen(true);
                    }}
                >
                    <StyledTableBody>
                        {mySamplesInfo && mySamplesInfo.length && mySamplesInfo.map((mySampleInfo, index) => {
                            const key = mySampleInfo?.sample?.sampleName;
                            const keySelectedMySampleInfo = selectedSampleInfo?.sample?.sampleName;

                            return <StyledTableRow
                                key={index}
                                hover
                                style={{ border: keySelectedMySampleInfo === key && '3px solid #1976D2', cursor: 'pointer' }}
                                onClick={() => {
                                    if (selectedSampleInfo && keySelectedMySampleInfo === key) {
                                        setSelectedSampleInfoIndex(null)
                                    } else {
                                        setSelectedSampleInfoIndex(index)
                                    }
                                }}
                            >
                                <StyledTableCellSmall>
                                    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                                        <IconButton
                                            disabled={isReadOnly}
                                            color="error"
                                            style={{ padding: 0 }}
                                        >
                                            <Delete
                                                fontSize="small"
                                                color="inherit"
                                                onClick={() => handleMySampleInfoDeleteIcon(mySampleInfo)}
                                            />
                                        </IconButton>
                                    </Box>
                                </StyledTableCellSmall>
                                <StyledTableCellMedium>
                                    {formatMidasNumber(mySampleInfo?.sample?.sampleName)}
                                </StyledTableCellMedium>
                                <StyledTableCellLarge>
                                    {mySampleInfo?.sample?.description}
                                </StyledTableCellLarge>
                            </StyledTableRow>
                        })}
                    </StyledTableBody>
                </UXDataTableWithoutBody>
            </Box>
            {
                selectedSampleInfo && !isReadOnly && <div className="wk-container-and-tests-bulk-location">
                    <LocationField
                        margin="dense"
                        fieldWidth={"11rem"}
                        value={bulkLocation}
                        handleChange={(value) => {
                            setBulkLocation(value);
                        }}
                        label="Final Location"
                        inputProps={{ style: { fontSize: 12 } }}
                    />

                    <GlobalButton
                        style={{ width: 160 }}
                        name="bulkLocation"
                        variant="contained"
                        onClick={() => {
                            setMethods(methods.map(method => ({
                                ...method,
                                requestedLocationName: bulkLocation
                            })));
                            setBulkLocation(null);
                        }}
                        disabled={!bulkLocation || methods.length < 1}
                    >
                        Bulk Final Location
                    </GlobalButton>
                </div>
            }
            {selectedSampleInfo && <Box marginTop={4}>
                <MethodSelection
                    width="100%"
                    selectedMethods={methods ?? []}
                    setSelectedMethods={(methods) => {
                        setMethods(methods.map(method => ({
                            ...method,
                            createFromContainer: null
                        })))
                    } }
                    showCompletionDate={true}
                    showContainerGrouping={false}
                    incomingTemplate={null}
                    showSearchTemplateName={false}
                    isReadOnly={isReadOnly || !selectedSampleInfo}
                    showEstimateAndPriority={true}
                    showFinalLocation={true}
                    maxMethodsAllowed={999}
                />
                {!workRequestId && methodFacilitiesExternal?.length > 0 && <label>This work request will get splitted into another {methodFacilitiesExternal.length} requests</label>}
            </Box>}
            {isProcess && 
                mySamplesInfo.length > 0 && 
                methods.filter((x) => x.methodFacility).length > 0 && 
                selectedSampleInfo &&
                !isReadOnly && <WorkRequestContainerManagementWithoutContainer
                availableLocations={availableLocations}
                mySamplesInfo={mySamplesInfo}
                setMySamplesInfo={setMySamplesInfo}
                methods={methods}
                setMethods={setMethods}
                setContaineringMethod={setContaineringMethod}
                containeringMethod={containeringMethod}
                selectedSampleInfo={selectedSampleInfo}
                availableUOMs={unitOfMeasure}
                key={`${selectedSampleInfoIndex} - ${selectedSampleInfoIndex}`}
                prepareData={prepareData}
                myWorkRequest={myWorkRequest}
            />}
            {addCommentComponent()}
            {isMySampleInfoModalOpen && <AddSamples
                isModalOpen={isMySampleInfoModalOpen}
                setIsModalOpen={setIsMySampleInfoModalOpen}
                setIsModalClose={onCloseMySampleInfoModal}
                mySamplesInfo={mySamplesInfo}
                setMySamplesInfo={setMySamplesInfo}
            />}

            <ModalTwoButtons title={modalDeleteItemTitle} button1Text={modalDeleteItemButton1Text} button1Action={deleteItem} button2Text={modalDeleteItemButton2Text} button2Action={closeModalDeleteItem} open={modalDeleteItemOpen} setOpen={setModalDeleteItemOpen}>
                <label>
                    {modalDeleteItemText}
                </label>

                <div></div>
            </ModalTwoButtons>


            {/* Informational Messages */}
            <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
                <label>
                    {modalMessagesText}
                </label>
            </ModalMessages>
        </>
    )
}

export default SampleWithoutContainer